* {
    padding:0;
    margin:0;
    box-sizing: border-box;
}

body {
    width: 100%;
    height: 100vh;
}

#logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#Grupo_55 path{
    fill:#bdcc30;
    animation: grupo-55 0.5s linear infinite 1s;
}

#Grupo_56 path{
    fill:#bdcc30;
    animation: grupo-56 0.5s linear infinite 0.8s;
}

#Grupo_57 path{
    fill:#bdcc30;
    animation: grupo-57 0.5s linear infinite 0.6s;
}

#Grupo_58 path{
    fill:#bdcc30;
    animation: grupo-58 0.5s linear infinite 0.4s;
}

#Grupo_59 path{
    fill:#bdcc30;
    animation: grupo-59 0.5s linear infinite 0.2s;
}

#Grupo_60 path{
    fill:#bdcc30;
    animation: grupo-60 0.5s linear infinite ;
}

@keyframes grupo-55{
    0%{
        fill:#2E3240
    }
    17%{
        fill: #bdcc30;
    }
}

@keyframes grupo-56{
   17%{
        fill:#2E3240
    }
    34%{
        fill: #bdcc30;
    }
}

@keyframes grupo-57{
    34%{
        fill:#2E3240
    }
    51%{
        fill: #bdcc30;
    }
}

@keyframes grupo-58{
    51%{
        fill:#2E3240
    }
    68%{
        fill: #bdcc30;
    }
}

@keyframes grupo-59{
    68%{
        fill:#2E3240
    }
    85%{
        fill: #bdcc30;
    }
}

@keyframes grupo-60{
    85%{
        fill:#2E3240
    }
    100%{
        fill: #bdcc30;
    }
}


